import { CallStateType, selectIsStateLoading, selectResult, selectResultOrEmptyArray } from '@mbp/angular/ngrx';
import { SimpleCampaignDto } from '@mbp/mbp-brainlane-vouchers-api-client';
import { createSelector } from '@ngrx/store';

import { vouchersFeature } from './brainlane-vouchers.reducer';

export const selectCampaignsInWalletState = vouchersFeature.selectCampaignsInWallet;
export const selectCampaignsInWallet = createSelector(
    selectCampaignsInWalletState,
    (state) => state.result?.campaigns ?? [],
);
export const selectHasNoCampaigns = createSelector(
    selectCampaignsInWalletState,
    (s) => s.state === CallStateType.SUCCESS && s.result.campaigns.length === 0,
);
export const selectCampaignsTotal = createSelector(
    selectCampaignsInWalletState,
    (state) => state.result?.totalValue ?? 0,
);
export const selectCampaignsInWalletLoading = createSelector(selectCampaignsInWalletState, selectIsStateLoading);

export const selectCurrentCampaignId = vouchersFeature.selectCurrentCampaign;
export const selectCurrentCampaign = createSelector(
    selectCampaignsInWallet,
    selectCurrentCampaignId,
    (campaigns, campaignId) => campaigns?.find((c) => c.id === campaignId) ?? null,
);
export const selectCampaignName = createSelector(selectCurrentCampaign, (state): string => state?.name ?? '');

export const selectVouchersState = vouchersFeature.selectVouchers;
export const selectVouchersLoading = createSelector(selectVouchersState, selectIsStateLoading);
export const selectVouchers = createSelector(selectVouchersState, (s) => s.result?.vouchers ?? []);

export const selectVoucherInfoState = vouchersFeature.selectVoucherInfo;
export const selectVoucherInfoLoading = createSelector(selectVoucherInfoState, selectIsStateLoading);
export const selectVoucherInfo = createSelector(selectVoucherInfoState, selectResult);
export const selectConvertingVoucher = createSelector(vouchersFeature.selectConvertingVoucher, selectIsStateLoading);

export const selectHistoryState = vouchersFeature.selectHistory;
export const selectHistoryLoading = createSelector(selectHistoryState, selectIsStateLoading);
export const selectHistoryTransactions = createSelector(selectHistoryState, (s) => s.result?.transactions ?? []);
export const selectHistoryOrders = createSelector(selectHistoryState, (s) => s.result?.orders ?? []);

export const selectOrderSettingsState = vouchersFeature.selectOrderSettings;
export const selectOrderSettingsLoading = createSelector(selectOrderSettingsState, selectIsStateLoading);
export const selectOrderSettings = createSelector(selectOrderSettingsState, selectResult);

export const selectOrderCreationState = vouchersFeature.selectOrderCreation;
export const selectPendingOrder = createSelector(selectOrderCreationState, selectResult);
export const selectOrderCreationLoading = createSelector(selectOrderCreationState, selectIsStateLoading);

export const selectPaymentLinkState = vouchersFeature.selectPaymentLink;
export const selectPaymentLinkLoading = createSelector(selectPaymentLinkState, selectIsStateLoading);

export const selectIsProcessingOrder = createSelector(
    selectOrderCreationLoading,
    selectPaymentLinkLoading,
    (loading1, loading2) => loading1 || loading2,
);

export const selectOrdersState = vouchersFeature.selectOrders;
export const selectOrdersLoading = createSelector(selectOrdersState, selectIsStateLoading);
export const selectOrders = createSelector(selectOrdersState, selectResultOrEmptyArray);

export const selectOrderDetailsState = vouchersFeature.selectOrderDetails;
export const selectOrderDetailsLoading = createSelector(selectOrderDetailsState, selectIsStateLoading);
export const selectOrderDetails = createSelector(selectOrderDetailsState, selectResult);

export const selectTransactionsState = vouchersFeature.selectTransactions;
export const selectTransactionsLoading = createSelector(selectTransactionsState, selectIsStateLoading);
export const selectTransactions = createSelector(selectTransactionsState, selectResultOrEmptyArray);
export const selectTransactionCampaigns = createSelector(selectTransactions, (transactions) => {
    const map = new Map<string, SimpleCampaignDto>();
    for (const { campaign } of transactions) {
        if (map.has(campaign.id)) {
            continue;
        }
        map.set(campaign.id, campaign);
    }
    return Array.from(map.values());
});

export const selectTransactionDetailsState = vouchersFeature.selectTransactionDetails;
export const selectTransactionDetailsLoading = createSelector(selectTransactionDetailsState, selectIsStateLoading);
export const selectTransactionDetails = createSelector(selectTransactionDetailsState, selectResult);

export const selectMerchantsState = vouchersFeature.selectMerchants;
export const selectMerchantsLoading = createSelector(selectMerchantsState, selectIsStateLoading);
export const selectMerchants = createSelector(selectMerchantsState, (s) => s.result?.merchants ?? []);

export const selectIsCreatingAccount = createSelector(vouchersFeature.selectCurrentUser, selectIsStateLoading);
export const selectCurrentUser = createSelector(vouchersFeature.selectCurrentUser, selectResult);
